body {
  background-color: #445c8f;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: white; /* Branco */
  padding-top: 20px;
  position: fixed;  /* Faz com que o sidebar fique fixo na tela */
  top: 0;           /* Alinha o sidebar ao topo da página */
  left: 0;          /* Alinha o sidebar ao lado esquerdo da página */
  z-index: 1000;    /* Garante que o sidebar esteja acima de outros elementos */
}

.logo {
  position: relative;
  font-size: 1.5rem;
  text-align: center;
}

.logo-class {
  width: 170px; /* Defina a largura desejada */
  height: auto; /* Mantém a proporção da imagem */
  margin-top: -20%; /* Ajusta a posição vertical, mas pode ser ajustado conforme necessário */
}

.nav-link {
  color: #445c8f; /* Laranja por do sol */
  margin-bottom: 8px; /* Tamanho do botão */
  font-size: 1.2rem;
  text-align: left;
  padding-left: 10px;
  width: 100%;
  background-color: #445c8fda; /* Laranja semi-transparente */
  border-radius: 5px;
}

.nav-link:hover {
  background-color: #445c8f9f; /* Fundo laranja mais forte ao passar o mouse */
}

/* Conteúdo do centro */
.content-container {
  max-width: 800px; /* Ajuste para a largura desejada */
  padding: 30px; /* Espaçamento interno */
  margin-left: 270px; /* Ajusta a margem esquerda para compensar o sidebar */
  color: white;
}

.content-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px; /* Espaçamento inferior */
}

.content-video {
  width: 100%;
  height: auto;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .sidebar {
    width: 200px; /* Reduz a largura do sidebar em dispositivos móveis */
  }

  .content-container {
    margin-left: 220px; /* Ajusta a margem esquerda para compensar o sidebar */
    padding: 15px; /* Diminui o padding para melhor uso do espaço */
  }

  .nav-link {
    font-size: 1rem; /* Reduz o tamanho da fonte */
  }

  .btnComece {
    left: 10%; /* Ajusta a posição do botão */
    width: 80%; /* Altera a largura do botão para se ajustar à tela */
  }

  .logo-grid {
    grid-template-columns: repeat(2, 1fr); /* Muda para 2 colunas em telas menores */
  }

  .emoticon-grid {
    grid-template-columns: repeat(2, 1fr); /* Muda para 2 colunas em telas menores */
  }

  .comercio-grid {
    grid-template-columns: 1fr; /* Garante uma coluna em telas menores */
  }
}

@media (max-width: 480px) {
  .sidebar {
    display: none; /* Oculta o sidebar em telas muito pequenas */
  }

  .content-container {
    margin-left: 0; /* Remove a margem esquerda */
    padding: 10px; /* Ajusta o padding */
  }

  .nav-link {
    font-size: 0.9rem; /* Diminui ainda mais o tamanho da fonte */
  }

  .btnComece {
    width: 100%; /* Botão ocupa toda a largura disponível */
    margin-top: 20px; /* Aumenta o espaço acima do botão */
  }

  .logo-grid {
    grid-template-columns: repeat(1, 1fr); /* Muda para 1 coluna em telas muito pequenas */
  }

  .emoticon-grid {
    grid-template-columns: repeat(2, 1fr); /* Muda para 2 colunas em telas muito pequenas */
  }
}
